<template>
  <VAsync :await="request">
    <template #default="{ pending, error }">
      <BLoading v-if="pending" :is-full-page="false" active />
      <BMessage
        v-else-if="error"
        type="is-danger"
        has-icon
        icon-size="is-small"
      >
        {{ $t('unexpected-error-info-message') }}
      </BMessage>
      <div v-else>
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td colspan="2">
                <strong>{{ $t('terms') }}</strong>
                <div
                  v-for="term in activeTerms"
                  :key="term.id"
                  class="flex justify-between"
                >
                  <div class="flex align-center gap-8 pr-4">
                    <div
                      class="inline-block p-2"
                      :style="{ background: term.color }"
                    />
                    {{ term.name }}
                    <span v-if="currentUser.adminByNow" class="term-id">({{ term.id }})</span>
                  </div>
                  <div>
                    <IdxBtn small class="mr-2" @click="openTermModal(term)">
                      <span class="icon is-small">
                        <i class="fas fa-edit" />
                      </span>
                    </IdxBtn>
                    <IdxBtn small @click="confirmTermDeletion(term)">
                      <span class="icon is-small">
                        <i class="far fa-trash-alt" />
                      </span>
                    </IdxBtn>
                  </div>
                </div>
                <div class="my-2 text-center">
                  <IdxBtn small @click="openTermModal()">
                    {{ $t('add-term') }}
                  </IdxBtn>
                </div>
                <template v-if="deletedTerms.length && showDeletedTerms">
                  <strong>{{ $t('deleted-terms') }}</strong>
                  <div
                    v-for="term in deletedTerms"
                    :key="term.id"
                    class="flex justify-between"
                  >
                    <div class="flex align-center gap-8 pr-4">
                      <div
                        class="inline-block p-2"
                        :style="{ background: term.color }"
                      />
                      {{ term.name }}
                      <span v-if="currentUser.adminByNow" class="term-id"
                        >({{ term.id }})</span
                      >
                      <div>
                        <IdxBtn small class="mr-2" @click="restoreTerm(term)">
                          {{ $t('restore') }}
                        </IdxBtn>
                      </div>
                    </div>
                  </div>
                  <IdxBtn class="my-4" link @click="toggleDeletedTerms">
                    {{ $t('deleted-terms-hide') }}
                  </IdxBtn>
                </template>
                <template v-if="deletedTerms.length && !showDeletedTerms">
                  <IdxBtn class="my-4" link @click="toggleDeletedTerms">
                    {{ $t('deleted-terms-show') }}
                  </IdxBtn>
                </template>
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('studies') }}</strong>
              </td>
              <td>
                <template v-if="projects.length">
                  <span v-for="(project, index) in projects" :key="project.id">
                    <RouterLink :to="`/project/${project.id}`">
                      {{ project.name }}
                    </RouterLink>
                    <span v-if="index < projects.length - 1">, </span>
                  </span>
                </template>
                <em v-else-if="projects.length > 0" class="has-text-grey">
                  {{ $t('project-used-in-no-access') }}
                </em>
                <em v-else class="has-text-grey">
                  {{ $t('project-not-used-in-any') }}
                </em>
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ $t('creator') }}</strong>
              </td>
              <td>
                {{ creatorFullname || $t('unknown') }}
              </td>
            </tr>
            <tr v-if="currentUser.adminByNow">
              <td>
                <strong>{{ $t('meta') }}</strong>
              </td>
              <td>
                <VForm class="flex gap-8" @submit.prevent="updateMeta">
                  <template #default="form">
                    <IdxInput
                      v-model="metaText"
                      label="Meta"
                      hide-label
                      type="textarea"
                      name="meta"
                      class="flex-grow"
                    />
                    <IdxBtn
                      type="submit"
                      :disabled="!form.valid || !isValidJson(metaText)"
                    >
                      {{ $t('save') }}
                    </IdxBtn>
                  </template>
                </VForm>
                ({{ $t('json-formats-only') }})
              </td>
            </tr>
            <tr v-if="canEdit">
              <td>
                <strong>{{ $t('actions') }}</strong>
              </td>
              <td>
                <IdxBtn class="mr-2" @click="isRenameModalActive = true">
                  {{ $t('button-rename') }}
                </IdxBtn>
                <IdxBtn
                  :disabled="projects.length > 0"
                  :title="
                    projects.length
                      ? $t('cannot-delete-ontology-with-projects')
                      : ''
                  "
                  color="danger"
                  @click="confirmDeletion"
                >
                  {{ $t('delete') }}
                </IdxBtn>
              </td>
            </tr>
          </tbody>
        </table>

        <RenameModal
          :title="$t('rename-ontology')"
          :current-name="ontology.name"
          :active.sync="isRenameModalActive"
          @rename="rename"
        />
      </div>
    </template>
  </VAsync>
</template>

<script>
import noteApi from '../../services/noteApi.js';
import RenameModal from '../utils/RenameModal.vue';
import { fullName } from '../../utils/user-utils.js';

/**
 * @typedef {{
 * id: number
 * name: string
 * user: number
 * terms: Array<any>
 * }} Ontology
 */

export default {
  name: 'OntologyDetails',
  components: {
    RenameModal,
  },
  props: {
    /** @type {import('vue').PropOptions<{ id: number, name: string }>} */
    ontology: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      request: null,
      /** @type {Ontology} */
      fullOntology: null,
      projects: [],
      managedProjects: [],
      creatorFullname: null,
      showDeletedTerms: false,

      isRenameModalActive: false,
      metaInfo: null,
      metaText: '',
    };
  },
  computed: {
    /** @returns {object} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    canEdit() {
      const { currentUser, projects } = this;
      // Check if they are a guest
      if (currentUser.guestByNow) return false;
      // Check if they are an admin/superadmin
      if (currentUser.admin) return true;
      // Check if they are the ontology owner
      if (currentUser.id === this.fullOntology.user) return true;
      // Check if they have access to all projects in ontology
      if (this.nbProjects !== projects.length) return false;
      // Check if all projects are collaborative or user can manage all projects
      if (
        !projects.some((p) => p.isRestricted || p.isReadOnly) ||
        !projects.some(
          (p) => !this.managedProjects.find((mp) => mp.id === p.id)
        )
      ) {
        return true;
      }
      return false;
    },
    /** @returns {number} */
    nbProjects() {
      const fullOntology = this.fullOntology;
      if (!fullOntology) return 0;
      return this.projects.length;
    },
    activeTerms() {
      const fullOntology = this.fullOntology;
      if (!fullOntology) return [];
      return fullOntology.terms.filter((t) => !t.deleted);
    },
    deletedTerms() {
      const fullOntology = this.fullOntology;
      if (!fullOntology) return [];
      return fullOntology.terms.filter((t) => t.deleted);
    },
  },
  watch: {
    ontology: {
      handler() {
        this.fetchOntology();
        this.getMeta();
      },
      immediate: true,
    },
    metaInfo() {
      if (this.metaInfo == 'null') {
        this.metaText = '';
      } else {
        this.metaText = this.metaInfo;
      }
    },
  },
  methods: {
    fetchOntology() {
      const ontologyId = this.ontology.id;
      this.request = async () => {
        try {
          const requests = [
            // Ontology details
            noteApi.get(
              `/napi/ontology/${ontologyId}?includeDeletedTerms=true`
            ),
            // Ontology projects current user has access to
            noteApi
              .get(`/api/ontology/${ontologyId}/project.json`)
              .then((r) => r.collection),
            // Current user's managed projects
            noteApi
              .get(`/api/user/${this.currentUser.id}/project/light.json`, {
                query: {
                  max: 0,
                  admin: true,
                },
              })
              .then((r) => r.collection),
          ];

          const [fullOntology, projects, managedProjects] = await Promise.all(
            requests
          );

          this.fullOntology = fullOntology;
          // projects prop of ontology contains all projects, including those that the current user cannot see => need to refetch the collection
          this.projects = projects;
          this.managedProjects = managedProjects;

          const creator = await noteApi.get(
            `/api/user/${fullOntology.userId}.json`
          );
          this.creatorFullname = fullName(creator);
        } catch (error) {
          console.log(error);
        }
      };
    },
    isValidJson(input) {
      try {
        JSON.parse(input);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getMeta() {
      try {
        const response = await noteApi.get(
          `/napi/ontology/${this.ontology.id}`
        );
        this.metaInfo = JSON.stringify(response.meta);
      } catch (error) {
        console.log(error);
      }
    },
    async updateMeta({ target }) {
      try {
        const data = new FormData(target);
        const r = await noteApi.patch(`/napi/ontology/${this.ontology.id}`, {
          data: data,
        });
        console.log(r);
      } catch (error) {
        console.log(error);
      }
    },

    async rename(newName) {
      try {
        await noteApi.patch(`/napi/ontology/${this.ontology.id}`, {
          json: {
            name: newName,
          },
        });
        this.fullOntology.name = newName;
        this.$notify({
          type: 'success',
          text: this.$t('ontology-rename-success', {
            name: newName,
          }),
        });
        this.$emit('rename', newName);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('ontology-rename-failed', { name: this.ontology.name }),
        });
      }
      this.isRenameModalActive = false;
    },

    openTermModal(term) {
      this.$buefy.modal.open({
        parent: this,
        component: () => import('./TermModal.vue'),
        props: {
          term: term,
          ontology: this.fullOntology,
        },
        events: {
          newTerm: () => {
            this.request = async () => {
              this.fullOntology = await noteApi.get(
                `/napi/ontology/${this.ontology.id}`
              );
            };
          },
          updateTerm: this.updateTerm,
        },
        hasModalCard: true,
      });
    },
    updateTerm(term) {
      const fullOntology = this.fullOntology;
      const index = fullOntology.terms.findIndex((item) => item.id === term.id);
      this.fullOntology.terms.splice(index, 1, term);
    },

    confirmTermDeletion(term) {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-term', { name: term.name }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.deleteTerm(term),
      });
    },
    async deleteTerm(term) {
      try {
        const fullOntology = this.fullOntology;
        const index = fullOntology.terms.findIndex(
          (item) => item.id === term.id
        );

        // TODO: This is broken in Cytomine API. Replace with ours.
        await noteApi.delete(`/api/term/${term.id}.json`);

        this.fullOntology.terms[index].deleted = new Date();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-deletion'),
        });
      }
    },

    async restoreTerm(term) {
      try {
        const success = await noteApi.put(
          `/napi/ontology/${this.ontology.id}/term/${term.id}/enable`
        );
        if (success && !success.error) {
          term.deleted = null;
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-restoration'),
        });
      }
    },

    confirmDeletion() {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-ontology', {
          name: this.ontology.name,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.$emit('delete'),
      });
    },

    toggleDeletedTerms() {
      this.showDeletedTerms = !this.showDeletedTerms;
    },
  },
};
</script>
<style lang="scss" scoped>
.term-id {
  font-size: 0.8rem;
  color: #7f8c8d;
}
</style>
