<template>
  <form @submit.prevent="createOntology()">
    <CytomineModal
      :active="active"
      :title="$t('create-ontology')"
      @close="$emit('update:active', false)"
    >
      <b-field
        :label="$t('name')"
        :type="{ 'is-danger': errors.has('name') }"
        :message="errors.first('name')"
      >
        <b-input v-model="name" v-validate="'required'" name="name" />
      </b-field>

      <template #footer>
        <button
          class="button"
          type="button"
          @click="$emit('update:active', false)"
        >
          {{ $t('cancel') }}
        </button>
        <button :disabled="errors.any()" class="button is-link">
          {{ $t('save') }}
        </button>
      </template>
    </CytomineModal>
  </form>
</template>

<script>
import { Ontology } from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal';

export default {
  name: 'AddOntologyModal',
  components: { CytomineModal },
  props: {
    active: Boolean,
  },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      name: '',
    };
  },
  watch: {
    active(val) {
      if (val) {
        this.name = '';
      }
    },
  },
  methods: {
    async createOntology() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      try {
        const ontology = await new Ontology({ name: this.name }).save();
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-ontology-creation'),
        });
        this.$emit('newOntology', ontology);
        this.$emit('update:active', false);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-ontology-creation'),
        });
      }
    },
  },
};
</script>
