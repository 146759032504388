<template>
  <div v-if="!currentUser.admin" class="box error">
    <h2 class="mb-3">
      {{ $t('access-denied') }}
    </h2>
    <p>{{ $t('insufficient-permission') }}</p>
  </div>
  <div v-else class="content-wrapper">
    <BLoading :is-full-page="false" :active="loading" />

    <template v-if="!loading">
      <div v-if="!ontologies" class="box error">
        <h2 class="mb-3">
          {{ $t('error') }}
        </h2>
        <p>{{ $t('unexpected-error-info-message') }}</p>
      </div>

      <div v-else-if="ontologies.length > 0" class="columns">
        <div class="column is-one-quarter">
          <div class="panel flex flex-column">
            <p class="panel-heading flex align-center justify-between">
              {{ $t('ontologies') }}
              <button
                v-if="!currentUser.guestByNow"
                class="button is-link"
                @click="creationModal = true"
              >
                {{ $t('new') }}
              </button>
            </p>
            <div class="panel-block">
              <BInput
                v-model="searchString"
                :placeholder="$t('search')"
                size="is-small"
                type="search"
                icon="search"
              />
            </div>
            <div class="panel-main-content">
              <a
                v-for="ontology in filteredOntologies"
                :key="ontology.id"
                :class="{ 'is-active': isSelected(ontology) }"
                class="panel-block"
                @click="selectOntology(ontology)"
              >
                <span class="panel-icon">
                  <i
                    v-if="isSelected(ontology)"
                    class="fas fa-caret-right"
                    aria-hidden="true"
                  />
                </span>
                {{ ontology.name }}
              </a>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="panel">
            <p class="panel-heading">
              {{ selectedOntology ? selectedOntology.name : $t('not-found') }}
            </p>
            <div class="panel-block panel-main-content">
              <OntologyDetails
                v-if="selectedOntology"
                :ontology="selectedOntology"
                @delete="deleteOntology"
                @rename="renameOntology"
              />
              <BMessage v-else type="is-danger" has-icon icon-size="is-small">
                {{ $t('not-found-error') }}
              </BMessage>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="box error">
        <div class="columns align-center">
          <p class="column">
            {{ $t('dont-have-access-to-any-ontology') }}
          </p>
          <p class="column has-text-right">
            <button
              v-if="!currentUser.guestByNow"
              class="button is-link"
              @click="creationModal = true"
            >
              {{ $t('new') }}
            </button>
          </p>
        </div>
      </div>
    </template>

    <AddOntologyModal :active.sync="creationModal" @newOntology="addOntology" />
  </div>
</template>

<script>
import noteApi from '../../services/noteApi';

import OntologyDetails from './OntologyDetails';
import AddOntologyModal from './AddOntologyModal';
import { getWildcardRegexp } from '@/utils/string-utils';

export default {
  name: 'ListOntologies',
  components: {
    OntologyDetails,
    AddOntologyModal,
  },
  data() {
    return {
      loading: true,
      ontologies: null,
      selectedOntology: null,
      creationModal: false,
      searchString: '',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    idTargetOntology() {
      return (
        Number(this.$route.params.idOntology) ||
        this.$store.state.ontologies.selectedOntology
      );
    },
    filteredOntologies() {
      if (this.searchString.length > 0) {
        const regexp = getWildcardRegexp(this.searchString);
        return this.ontologies.filter((ontology) => regexp.test(ontology.name));
      }
      return this.ontologies;
    },
  },
  watch: {
    idTargetOntology() {
      if (!this.idTargetOntology) {
        this.selectedOntology = this.ontologies[0];
        return;
      }
      if (
        this.selectedOntology &&
        this.selectedOntology.id === this.idTargetOntology
      ) {
        return;
      }
      this.selectTargetOntology();
    },
  },
  async created() {
    try {
      const { collection } = await noteApi.get(`/api/ontology.json`, {
        query: {
          max: 0,
          light: true,
        },
      });
      this.ontologies = collection;
    } catch (error) {
      console.log(error);
      this.loading = false;
      return;
    }
    this.sortOntologies();

    if (this.idTargetOntology) {
      this.selectTargetOntology();
    } else {
      this.selectedOntology = this.ontologies[0];
    }

    this.loading = false;
  },
  methods: {
    selectOntology(ontology) {
      this.selectedOntology = ontology;
      this.$router.push(`/ontology/${this.selectedOntology.id}`);
    },
    selectTargetOntology() {
      this.selectedOntology = this.ontologies.find(
        (ontology) => ontology.id === this.idTargetOntology
      );
    },
    isSelected(ontology) {
      return this.selectedOntology && this.selectedOntology.id === ontology.id;
    },
    sortOntologies() {
      this.ontologies.sort((a, b) => a.name.localeCompare(b.name));
    },
    addOntology(ontology) {
      this.ontologies.push(ontology);
      this.sortOntologies();
      this.selectedOntology = ontology;
    },
    renameOntology(newName) {
      this.selectedOntology.name = newName;
      this.sortOntologies();
    },
    async deleteOntology() {
      const index = this.ontologies.findIndex(
        (ontology) => ontology.id === this.selectedOntology.id
      );
      try {
        await noteApi.delete(`/napi/ontology/${this.selectedOntology.id}`);
        this.ontologies.splice(index, 1);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-ontology-deletion', {
            name: this.selectedOntology.name,
          }),
        });
        this.selectedOntology = this.ontologies[0];
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-ontology-deletion'),
        });
      }
    },
  },
};
</script>

<style scoped>
.content-wrapper,
.columns,
.panel {
  height: 100%;
}

.panel-main-content {
  overflow: auto;
  flex-grow: 1;
}
</style>
